import { useEffect } from 'react'
import { navigate } from 'gatsby'

// redirect to members page
export default function Wewere() {
  useEffect(() => {
    navigate('/about/members/', { replace: true })
  }, [])

  return null
}
